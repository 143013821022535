import React, { useEffect, useState } from "react"
import Cookies from "universal-cookie"

const cookies = new Cookies()

const ReferralStateContext = React.createContext<string[]>([])
async function referralValidWithResponse(referral: string) {
  if (!referral) {
    return false
  }
  try {
    let result = await fetch(
      `https://jp5vfaodlc.execute-api.us-east-2.amazonaws.com/CheckSalesforceReports?var=${referral}`
    ).then((res) => res.json())
    if (!result.referralValid) {
      let referralArray = referral.split('-');
      if (referralArray.length > 2) {
        referral = `${referralArray[0]}-${referralArray[1]}`;
        result = await fetch(
          `https://jp5vfaodlc.execute-api.us-east-2.amazonaws.com/CheckSalesforceReports?var=${referral}`
        ).then((res) => res.json())
      }
    }
    return result
  } catch (e) {
    return false;
  }
}

export const hideCouponHeader = (ref: string[]) => {
  if (!ref) {
    return false;
  }
  let hide = false;
  ref.forEach((r: string) => {
    if (r.toLowerCase().includes('/n')) {
      hide = true
      return
    }
  });
  return hide
}

export const getCouponCodes = () => {
  let coupons: string[] = cookies.get("coupon");
  if (!coupons) {
    return ["COOLPERSONCREDIT"];
  }
  return coupons;
}

export const bannerMessage= () =>{
  let bannerMessage:any = cookies.get("bannerMessage");
  return bannerMessage ? bannerMessage : "";
}

export const ReferralProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState<string[]>([])

  useEffect(() => {
    let referrals : string[] = [];
    const referralCookies = Array.isArray(cookies.get("referral")) ? cookies.get("referral") : [];
    try {
      referrals = window.location.hash.split("#")[1].split("/")[0].split("&").filter(value => value !== '');
      if (!referrals) {
        referrals = referralCookies
      }
    } catch (e) {
      referrals = referralCookies
    }
    let validReferrals: string[] = [];
    let couponCodes: string[] = []; // Coupons for each valid referral
    let invalidReferrals: string[] = [];
    let banner = "";

    const fetchData = async () => {
      for (const referral of referrals) {
        await referralValidWithResponse(referral).then((response) => {
          if (response?.referralValid) {
            validReferrals.push(referral);
            couponCodes.push(response?.couponCode);
            // For now, set banner to the first valid referral's banner
            banner = response?.banner && !banner ? response?.banner : banner;
          } else {
            invalidReferrals.push(referral);
          }
        });
      }
      let expires = new Date()
      expires.setTime(expires.getTime() + 2 * 60 * 60 * 1000)
      cookies.set("referral", [], {expires})
      if (Array.isArray(validReferrals) && validReferrals.length > 0) {
        cookies.set("referral", validReferrals, {expires})
        cookies.set("bannerMessage", banner, {expires})
        cookies.set("coupon", couponCodes, {expires})
        setState(validReferrals)
      }
    };
    fetchData()
  }, [])

  const updateReferralState = (referrals: string[]) => {
    setReferralState(referrals).then((result) => {
      setState(result)
    });
  }

  return (
    <ReferralStateContext.Provider value={{ state, updateReferralState }}>
      {children}
    </ReferralStateContext.Provider>
  )
}

let Wrapper = ({ element }: { element: React.ReactChild }) => {
  return <ReferralProvider>{element}</ReferralProvider>
}

export default Wrapper

export function useReferralState() {
  const state :any = React.useContext(ReferralStateContext)
  return state?.state;
}

export function useUpdateReferralState() {
  const  state : any = React.useContext(ReferralStateContext);
  return state?.updateReferralState;
}

export function getReferralLink() {
  let referral = cookies.get("referral");
  if (referral && referral.length > 0) {
    return referral;
  }
  return [];
}

export async function setReferralState(referrals: string[]) {
  if (referrals) {
    const existId = cookies.get("referral");
    referrals = existId ? existId : referrals;
    let validReferrals: string[] = [];
    let couponCodes: string[] = []; // Coupons for each valid referral
    let invalidReferrals: string[] = [];

    for (const referral of referrals) {
      await referralValidWithResponse(referral).then((response) => {
        if (response?.referralValid) {
          validReferrals.push(referral);
          couponCodes.push(response?.couponCode);
        } else {
          invalidReferrals.push(referral);
        }
      });
    }
    let expires = new Date()
    expires.setTime(expires.getTime() + 2 * 60 * 60 * 1000)
    if (validReferrals.length > 0) {
      cookies.set("referral", validReferrals, {expires})
      cookies.set("coupon", couponCodes, {expires})
      return referrals;
    }
    cookies.set("referral", [], {expires})
  }
  return [];
};